import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";
import { useRecoilState } from "recoil";
import { collapsedState } from "../../utils/recoil-atoms";

const Navbar: React.FC = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  React.useEffect(() => {
    const elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  });

  const classOne = collapsed ? "collapse navbar-collapse" : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area">
        <div className="tarn-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to="/" onClick={() => setCollapsed(true)} className="navbar-brand">
                <StaticQuery
                  query={graphql`
                    query {
                      contentfulAdditionalAssets(title: { eq: "logo" }) {
                        title
                        asset {
                          fluid {
                            src
                          }
                        }
                      }
                    }
                  `}
                  render={({
                    contentfulAdditionalAssets: {
                      asset: {
                        fluid: { src },
                      },
                      title,
                    },
                  }) => <img src={src} alt={title} />}
                />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" activeClassName="active" className="nav-link">
                      Home
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/about/" activeClassName="active" className="nav-link">
                      About Us
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/our-work/" activeClassName="active" className="nav-link">
                      Our Work
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/services/" activeClassName="active" className="nav-link">
                      Services
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/solutions/" activeClassName="active" className="nav-link">
                      Solutions
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/services/hire-dedicated-developers/"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Dedicated Developers
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/blog/" activeClassName="active" className="nav-link">
                      Blog
                    </Link>
                  </li>
                </ul>

                <div className="others-option d-flex align-items-center">
                  <div className="option-item">
                    <Link
                      to="/contact/"
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="default-btn"
                    >
                      <i className="flaticon-right"></i> Contact Us
                      <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
