import { embeddedAssetBlockImageUi, embeddedEntryBlockUi } from "@/helpers/richTextEditorUi";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React, { FC } from "react";

interface FooterContentProps {
  content: any;
}

const FooterContent: FC<FooterContentProps> = ({ content }) => {
  const options = {
    renderText: (text) => text.replace("!", "?"),

    renderNode: {
      "embedded-asset-block": (node) => embeddedAssetBlockImageUi(node, content),
      "embedded-entry-block": (node) => embeddedEntryBlockUi(node, content),
    },
  };

  return (
    <div className="container pb-4">
      {documentToReactComponents(JSON.parse(content?.raw), options)}
    </div>
  );
};

export default FooterContent;
