import { graphql, Link, StaticQuery } from "gatsby";
import React from "react";
import FooterContent from "../Shared/extra/FooterContent";

interface FooterProps {
  footerContent?: any;
}

const Footer: React.FC<FooterProps> = ({ footerContent }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-area bg-color">
      {footerContent ? <FooterContent content={footerContent} /> : ""}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <Link to="/" className="logo">
                <StaticQuery
                  query={graphql`
                    query {
                      contentfulAdditionalAssets(title: { eq: "logo" }) {
                        title
                        asset {
                          fluid {
                            src
                          }
                        }
                      }
                    }
                  `}
                  render={({
                    contentfulAdditionalAssets: {
                      asset: {
                        fluid: { src },
                      },
                      title,
                    },
                  }) => <img src={src} alt={title} />}
                />
              </Link>
              <p>
                Zartek is the leading mobile app development company based in Toronto, Canada with
                expertise in mass consumer technology products.
              </p>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Explore</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about/">About</Link>
                </li>
                <li>
                  <Link to="/our-work/">Our Work</Link>
                </li>
                <li>
                  <Link to="/services/">Services</Link>
                </li>
                <li>
                  <Link to="/contact/">Contact</Link>
                </li>
                <li>
                  <a
                    href="https://tyiilhexsjg.typeform.com/to/LiVxOGBv"
                    target="_"
                    rel="noreferrer"
                  >
                    Partner with us
                  </a>
                </li>
                <li>
                  <Link to="/careers/">Careers</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3>Locations</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/">Toronto, Canada</Link>
                </li>
                <li>
                  <a href="https://www.zartek.qa" target="_blank" rel="noreferrer">
                    Doha, Qatar
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.zartek.in/app-development-hyderabad"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Hyderabad, India
                  </a>
                </li>
                <li>
                  <a href="https://www.zartek.in" target="_blank" rel="noreferrer">
                    Kochi, India
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <h3>Address</h3>

              <ul className="footer-contact-info">
                <li>
                  <i className="bx bx-map"></i>
                  Zartek Technologies Canada inc.
                  <br />
                  180 Northfield Dr <br /> Waterloo, ON N2L 0C7, Canada
                </li>
                <li>
                  <i className="bx bx-phone-call"></i>
                  <a id="footer_phone_ref" href="tel:+91 8069647545">
                    +91 8069647545
                  </a>
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto: info@zartek.ca" id="footer_email_link">
                    {" "}
                    info@zartek.ca
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                Copyright @{currentYear} <strong>Zartek</strong> All rights reserved{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src="/images/footer-map.png" alt="footer-logo" />
      </div>
    </footer>
  );
};

export default Footer;
