/* eslint-disable react/no-unescaped-entities */
import React, { FC } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

interface FrequentlyAskedQuestionsProps {
  list: { question: string; id: string; answer: { answer: string } }[];
}

const FrequentlyAskedQuestions: FC<FrequentlyAskedQuestionsProps> = ({ list }) => {
  return (
    <div className="container mb-5">
      <div className="faq-accordion">
        <h2 className="mb-3 mt-1">Frequently Asked Questions</h2>
        <Accordion>
          {list.map((each) => {
            return (
              <AccordionItem key={each.id}>
                <AccordionItemHeading>
                  <AccordionItemButton>{each.question}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>{each.answer.answer}</p>
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
