import FrequentlyAskedQuestions from "@/components/Shared/Design/FrequentlyAskedQuestions";
import React from "react";

export const embeddedEntryBlockUi: React.FC = (node: any, content: any) => {
  const assetId = node?.data?.target?.sys?.id;

  if (!assetId) return;

  const enteredBlockEntry = content?.references?.find((each) => each.contentful_id === assetId);

  if (!enteredBlockEntry) return;

  if (enteredBlockEntry?.__typename === "ContentfulTitleWithSectionStartedRichTextEditorRef") {
    return (
      <div className="services-details-desc">
        <span className="sub-title">{enteredBlockEntry?.title}</span>
        <h3>{enteredBlockEntry?.sectionStarterTitle}</h3>
      </div>
    );
  }

  if (enteredBlockEntry?.__typename === "ContentfulSectionTitleLeftImageAndRightPointsRich") {
    return (
      <div className="services-details-desc">
        <h5>{enteredBlockEntry?.section}</h5>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="image">
              <img src={enteredBlockEntry?.image?.file?.url} alt={enteredBlockEntry?.section} />
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="content">
              <ul>
                {enteredBlockEntry?.points?.points?.map((point, i) => {
                  return <li key={i}>{point}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (enteredBlockEntry?.__typename === "ContentfulSectionTitleRightImageAndLeftPoints") {
    return (
      <div className="services-details-desc">
        <h5>{enteredBlockEntry?.section}</h5>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="content">
              <ul>
                {enteredBlockEntry?.points?.points?.map((point, i) => {
                  return <li key={i}>{point}</li>;
                })}
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="image">
              <img src={enteredBlockEntry?.image?.file?.url} alt={enteredBlockEntry?.section} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (enteredBlockEntry?.__typename === "ContentfulTitleWithBulletPointBelowRichTextEditorRef") {
    return (
      <div className="services-details-desc">
        <h3>{enteredBlockEntry?.title}</h3>
        <ul className="technologies-features">
          {enteredBlockEntry?.bulletPoints?.map((point, i) => {
            return (
              <li key={i}>
                <span>{point}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  if (enteredBlockEntry?.__typename === "ContentfulApplicationAreasServiceDetailEditorRef") {
    return (
      <div className="services-details-desc">
        <h3>Application Areas</h3>

        <div className="row">
          {enteredBlockEntry?.applicationsAreasList?.map(
            ({
              icon: {
                file: { url },
              },
              applicationAreaName,
              id,
            }) => {
              return (
                <div className="col-lg-4 col-sm-6 col-md-6" key={id}>
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <img src={url} alt={"icon"} />
                    </div>
                    {applicationAreaName}
                  </div>
                </div>
              );
            },
          )}
        </div>
      </div>
    );
  }

  if (enteredBlockEntry?.__typename === "ContentfulBulletPointsRichTextEditorRef") {
    return (
      <div className="services-details-desc">
        <ul style={{ display: "block" }} className="technologies-features">
          {enteredBlockEntry?.points?.map((point, i) => {
            return (
              <li style={{ maxWidth: "100%" }} key={i}>
                <span>{point}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  if (enteredBlockEntry?.__typename === "ContentfulFrequentlyAskedQuestionListForEmbeddedView") {
    return <FrequentlyAskedQuestions list={enteredBlockEntry.list} />;
  }
  return <p>View Missing</p>;
};

export const embeddedAssetBlockImageUi: React.FC = (node: any, content: any) => {
  const assetId = node?.data?.target?.sys?.id;

  const asset = content?.references?.find((each) => each.contentful_id === assetId);
  if (!asset?.file.hasOwnProperty("url")) {
    return null;
  }

  return (
    <div className="services-details-image">
      <img alt={"service-asset"} src={asset?.file?.url} />
    </div>
  );
};
