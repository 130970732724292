import React, { useEffect, useRef } from "react";
import { RecoilRoot } from "recoil";
import Footer from "./Footer";
import GoTop from "./GoTop";
import Navbar from "./Navbar";

interface LayoutProps {
  footerContent?: any;
}

const Layout: React.FC<LayoutProps> = ({ children, footerContent }) => {
  const chatSubBtn = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (typeof document === "undefined" || typeof window === "undefined") {
      return;
    }

    let isCancelled = false;

    if (!isCancelled) {
      (function (w: any, d) {
        if (d.getElementById("chat-bot-launcher-container")) {
          return;
        }
        w.CollectId = "6087d7ef34b8b76f099f00fb";
        const h = d.head || d.getElementsByTagName("head")[0];
        const s = d.createElement("script");
        s.setAttribute("type", "text/javascript");
        s.setAttribute("src", "https://collectcdn.com/launcher.js");
        s.onload = function (ev) {
          const collectchat = w.collectchat || {};

          collectchat.on("complete", () => {
            chatSubBtn.current?.click();
          });
        };
        h.appendChild(s);
      })(window, document);
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <RecoilRoot>
      <Navbar />
      {children}
      <GoTop scrollStepInPx={100} delayInMs={10.5} />
      <Footer footerContent={footerContent} />
      <button id="chat-bot-ref" style={{ display: "none" }} ref={chatSubBtn}></button>
    </RecoilRoot>
  );
};

export default Layout;
